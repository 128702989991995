<template>
  <div class="tasks-list-container">
    <draggable
      v-for="(list, listIndex) in lists"
      :key="listIndex"
      tag="div"
      :list="tasksByList[list.link]"
      :style="resultListWidth"
      @change="onChange($event, list)"
      class="tasks-list"
      v-bind="dragOptions"
      @start="onStartDrag"
      @end="onStopDrag"
      @sort="onSort(tasksByList[list.link])"
      :disabled="interactive == null"
    >
      <transition-group :name="!drag ? 'flip-list' : null" type="transition" tag="div" class="task-list-col">
        <task
          v-for="(element, taskIndex) in tasksByList[list.link]"
          :template="template"
          :component-style="taskStyle"
          :key="taskIndex"
          :data="element"
        />
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Task from '../Task'
import Data from '@/components/InterfaceEditor/components/basic/Tasks/Data'

export default {
  name: 'tasks-container',
  inject: ['getEventBus'],
  props: {
    interactive: {
      type: String,
      default: null
    },
    defaultOrder: {
      type: Object,
      default: {},
      description: 'Сортировка'
    },
    template: {
      type: Array,
      default: () => {
        return []
      }
    },
    taskStyle: {
      type: String,
      default: ''
    },
    lists: {
      type: Array,
      default () {
        return []
      }
    },
    tasks: {
      type: Array,
      default () {
        return []
      }
    },
    conditionMoving: {
      type: Object,
      default () {
        return {}
      }
    },
    resultListWidth: {
      type: String,
      default: 'min-width: 300px'
    },
    additionalFields: {
      type: Array,
      default: () => []
    }
  },
  components: {
    draggable,
    Task,
    Data
  },
  methods: {
    onStartDrag (element) {
      this.drag = true
      this.activeBlock = element
      this.parentContainerLeftScroll = element.item.closest('.custom_scrollbar')
      this.parentContainerPosition = this.parentContainerLeftScroll
        .getBoundingClientRect()
      this.parentContainerTopScroll = element.item.closest('.el-scrollbar__wrap')
      element.item.addEventListener('drag', this.scrollToView)
    },
    onStopDrag () {
      this.drag = false
      this.parentContainerLeftScroll = undefined
      this.parentContainerPosition = undefined
      this.parentContainerTopScroll = undefined
      this.activeBlock.item.removeEventListener('drag', this.scrollToView)
      this.activeBlock = undefined
    },
    scrollToView (event) {
      const speed = 50
      const left = event.clientX - this.parentContainerPosition.left
      if (left < 5) {
        this.parentContainerLeftScroll.scroll({ left: this.parentContainerLeftScroll.scrollLeft - speed })
      } else if (left > (this.parentContainerPosition.width - 5)) {
        this.parentContainerLeftScroll.scroll({ left: this.parentContainerLeftScroll.scrollLeft + speed })
      }

      const top = event.clientY - this.parentContainerPosition.top - (this.activeBlock.item.clientHeight / 2)
      if (top < 5) {
        this.parentContainerTopScroll.scroll({ top: this.parentContainerTopScroll.scrollTop - speed })
      } else if (top > (this.parentContainerPosition.height - this.activeBlock.item.clientHeight)) {
        this.parentContainerTopScroll.scroll({ top: this.parentContainerTopScroll.scrollTop + speed })
      }
    },
    onSort (list) {
      if (list.length === 0 && this.defaultOrder.customSorting) {
        return list
      }
      list = Data.sortedItemsByField(list, this.defaultOrder.field, this.defaultOrder.direction)
    },
    onChange (event, list) {
      if (this.interactive !== 'drag_and_change_field') {
        return false
      }

      let eventChange = Object.keys(event)[0]
      let element = event[eventChange].element

      if (eventChange === 'added') {
        let conditionPassed = this.checkConditionMoving(element, list)
        if (!conditionPassed.success) {
          this.$notify.error({
            title: 'Error',
            dangerouslyUseHTMLString: true,
            message: conditionPassed.messages.join('<br>')
          })
          let link = element.link
          this.tasks.forEach(item => {
            if (item.id === element.id) {
              item.link = null
              item.link = link
            }
          })
          return false
        }
      }

      if (['added', 'moved'].includes(eventChange)) {
        if (this.defaultOrder.customSorting) {
          let lastElement
          let field = this.defaultOrder.field
          let updateItem = []
          this.tasksByList[list.link].map((item, key) => {
            if ([null, undefined].includes(item[field])) {
              item[field] = key
              updateItem.push(item)
            } else if (
              (lastElement !== undefined && (Number(lastElement[field]) === Number(item[field]) || Number(lastElement[field]) > Number(item[field]))) || item === element
            ) {
              let val = lastElement === undefined ? 0 : Number(lastElement[field]) + Number('0.001')
              item[field] = val.toFixed(3)
              updateItem.push(item)
            }
            lastElement = item
            return item
          })
          this.getEventBus().$emit('custom-change', updateItem)
        }

        if (eventChange === 'added') {
          element.link = list.link
          this.getEventBus().$emit('change', element)
        }
      }
    },
    checkConditionMoving (sourceItem, list) {
      let messages = []
      this.conditionMoving.simpleFields.forEach((condition) => {
        let ok = false
        let listItemValue = list[condition.listField]
        console.log('listItemValue', listItemValue)
        let sourceItemValue = sourceItem[condition.sourceField]
        console.log('sourceItemValue', sourceItemValue)
        try {
          try {
            if (!sourceItemValue) {
              sourceItemValue = null
              throw (null)
            }
            sourceItemValue = JSON.parse(sourceItemValue)
            if (sourceItemValue.length === 0) {
              sourceItemValue = null
              throw (null)
            }
            sourceItemValue.forEach((sourceVal) => {
              try {
                listItemValue = JSON.parse(listItemValue)
                listItemValue.forEach((listVal) => {
                  console.log(`'${sourceVal.name}' === '${listVal.name}'`)
                  if (eval(`'${sourceVal.id}' === '${listVal.id}'`) ||
                    eval(`'${sourceVal.name}' === '${listVal.name}'`)) { ok = true }
                })
              } catch (e) {
                console.log(`'${sourceVal.name}' === ${listItemValue}`)
                if (eval(`'${sourceVal.id}' === '${listItemValue}'`) ||
                  eval(`'${sourceVal.name}' === '${listItemValue}'`)) { ok = true }
              }
            })
          } catch {
            try {
              if (!listItemValue) {
                listItemValue = null
                throw (null)
              }
              listItemValue = JSON.parse(listItemValue)
              if (listItemValue.length === 0) {
                listItemValue = null
                throw (null)
              }
              listItemValue.forEach((listValue) => {
                console.log(`'${listValue.id}' === '${sourceItemValue}'`)
                console.log(`'${listValue.name}' === '${sourceItemValue}'`)
                if (eval(`'${listValue.id}' === '${sourceItemValue}'`) ||
                  eval(`'${listValue.name}' === '${sourceItemValue}'`)) { ok = true }
              })
            } catch {
              console.log(`${listItemValue} === ${sourceItemValue}`)
              if (eval(`'${listItemValue}' === '${sourceItemValue}'`)) ok = true
            }
          }
        } catch (e) {
          console.log(e)
        }
        if (!ok) {
          messages.push(condition.textError)
        }
      })
      this.conditionMoving.additionalFields.forEach((field) => {
        let fieldName = `${field.attribute}${field.type}`
        let listItemValue = list[fieldName]
        let sourceItemValue = Number(sourceItem[field.attribute])
        let newListValue = field.type === 'count' ? listItemValue + 1 : listItemValue + sourceItemValue
        if (!eval(`${newListValue} ${field.condition.operator} ${field.condition.value}`)) {
          messages.push(field.condition.textError)
        }
      })
      if (messages.length !== 0) {
        return { 'success': false, 'messages': messages }
      }
      return { 'success': true }
    }
  },
  computed: {
    tasksByList () {
      let tasks = {}
      this.lists.forEach((list) => {
        let listTasks = this.tasks.filter((item) => {
          return item.link === list.link
        })
        console.log(listTasks.forEach(item => { console.log(item.id) }))
        tasks[list.link] = Data.sortedItemsByField(listTasks, this.defaultOrder.field, this.defaultOrder.direction)
        console.log(tasks[list.link].forEach(item => { console.log(item.id) }))
        list = Data.updateFieldsItem(list, listTasks, this.additionalFields)
      })
      return tasks
    }
  },
  data () {
    return {
      scrolling: false,
      drag: false,
      activeBlock: undefined,
      parentContainerPosition: undefined,
      parentContainerLeftScroll: undefined,
      parentContainerTopScroll: undefined,
      dragOptions: {
        animation: 0,
        group: 'tasks',
        forceFallback: false,
        scrollSensitivity: 75,
        scrollSpeed: 30
      }
    }
  }
}
</script>

<style scoped>
  .tasks-list-container {
    display: flex;
    flex-direction: row;
  }

  .tasks-list-container .tasks-list {
    background: #E9EBED;
    min-height: 100px;
    margin-right: 10px;
    border-radius: 8px;
  }

  .tasks-list-container > .tasks-list > .task-list-col {
    height: 100%;
  }

</style>
